
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import SaifaireExpo from "./pages/newProject/LandingPage";
import CatComm from "./pages/newProject/Commerces/Categories";
// -- Components --
const Saifaire = lazy(() => import("./pages/Saifaire"));
const Commerces = lazy(() => import("./pages/Commerces"));
const VenteServices = lazy(() => import("./pages/VenteServices"));
const Fournisseurs = lazy(() => import("./pages/Fournisseurs"));
const Grandesespaces = lazy(() => import("./pages/Grandesespaces"));
const Soldes = lazy(() => import("./pages/Soldes"));
const Communaute = lazy(() => import("./pages/Communaute"));
const StandDefaut = lazy(() => import("./pages/StandDefaut"));
const Standcommerces = lazy(() => import("./pages/Standcommerces"));
const StandsSM = lazy(() => import("./pages/StandsSM"));
const StandCentreCom = lazy(() => import("./pages/StandCentreCom"));
const StandMarche = lazy(() => import("./pages/StandMarche"));
const StandBanques = lazy(() => import("./pages/StandBanques"));




// -- routes for page --
const Routing = ()=>{
    return(
        <BrowserRouter>
            <Suspense fallback={<p>Loading</p>}>
                <Routes>
                    <Route path="/" element = {<SaifaireExpo/>}></Route>
                    <Route exact path={"saifaireexpo/:pays"} element = {<SaifaireExpo/> }/>
                    <Route path="commerces" element = {<Commerces/>}></Route>
                    <Route path="commerces/categories" element = {<CatComm/>}></Route>
                    <Route path="venteservices" element = {<VenteServices/>}></Route>
                    <Route path="autres" element = {<Fournisseurs/>}></Route>
                    <Route path="grandesespaces" element = {<Grandesespaces/>}></Route>
                    <Route path="soldes" element = {<Soldes/>}></Route>
                    <Route path="communaute" element = {<Communaute/>}></Route>
                    <Route path="standdefaut" element = {<StandDefaut/>}></Route>
                    <Route path="standcommerces" element={<Standcommerces />}></Route>
                    <Route path="standsupermarche" element = {<StandsSM/>}></Route>
                    <Route path="standcentrecom" element = {<StandCentreCom/>}></Route>
                    <Route path="standmarche" element = {<StandMarche/>}></Route>
                    <Route path="standbanques" element = {<StandBanques/>}></Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
};
export default Routing;
