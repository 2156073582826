import React, {Component} from 'react';
import {useNavigate} from 'react-router-dom';
import Background from './../assets/images/wave.svg';


var ChoixRegion = (props) => {

	const navigate = useNavigate();

	function naviguer(props){
		navigate(props);
	}

	return(
		<div class="modal" id={props.id}>
	      <div class="modal-dialog">
	        <div class="modal-content">

	          <div class="modal-header">
	            <h4 class="modal-title">Veuillez choisir une region</h4>
	            <button type="button" class="close" data-dismiss="modal">&times;</button>
	          </div>

	          <div class="modal-body d-flex flex-wrap justify-content-center">
	          	
	          	<button onClick={() => naviguer(props.lien)} className="m-4 text-decoration-none btn" data-dismiss="modal">
		            <div className="d-flex flex-column">
		            	<span><strong className="text-dark">Region 1</strong></span>
		            	<span className="text-secondary">100 Boutiques</span>
		            </div>
	            </button>

	            <button onClick={() => naviguer(props.lien)} className="m-4 text-decoration-none btn" data-dismiss="modal">
		            <div className="d-flex flex-column">
		            	<span><strong className="text-dark">Region 1</strong></span>
		            	<span className="text-secondary">100 Boutiques</span>
		            </div>
	            </button>

	            <button onClick={() => naviguer(props.lien)} className="m-4 text-decoration-none btn" data-dismiss="modal">
		            <div className="d-flex flex-column">
		            	<span><strong className="text-dark">Region 1</strong></span>
		            	<span className="text-secondary">100 Boutiques</span>
		            </div>
	            </button>

	            <button onClick={() => naviguer(props.lien)} className="m-4 text-decoration-none btn" data-dismiss="modal">
		            <div className="d-flex flex-column">
		            	<span><strong className="text-dark">Region 1</strong></span>
		            	<span className="text-secondary">100 Boutiques</span>
		            </div>
	            </button>

	            <button onClick={() => naviguer(props.lien)} className="m-4 text-decoration-none btn" data-dismiss="modal">
		            <div className="d-flex flex-column">
		            	<span><strong className="text-dark">Region 1</strong></span>
		            	<span className="text-secondary">100 Boutiques</span>
		            </div>
	            </button>
	            
	            <button onClick={() => naviguer(props.lien)} className="m-4 text-decoration-none btn" data-dismiss="modal">
		            <div className="d-flex flex-column">
		            	<span><strong className="text-dark">Region 1</strong></span>
		            	<span className="text-secondary">100 Boutiques</span>
		            </div>
	            </button>
	          </div>

	          <div class="modal-footer">
	            <button type="button" class="btn btn-danger" data-dismiss="modal">Annuler</button>
	          </div>

	        </div>
	      </div>
	    </div>
	)

}

export default ChoixRegion;