import React , {Component} from 'react';
import ReactDOM from 'react-dom';
import SaifairePresentation from './../components/SaifairePresentation';
import Services from './../components/Services';
import Footer from './../components/Footer';


class Saifaire extends Component {
	constructor(props){
		super(props);
		this.state = {
			pays : null,
			icon : '/icons/def.png'
		};
	}

	paysClick = (e) => {
		this.setState({
			pays: null,
			icon: '/icons/def.png'
		});
	}

	belClick = (e) => {
		this.setState({
			pays: 'BEL',
			icon: '/icons/blg.png'
		});
	}

	cndClick = (e) => {
		this.setState({
			pays: 'CND',
			icon: '/icons/cnd.png'
		});
	}

	frClick = (e) => {
		this.setState({
			pays: 'FR',
			icon: '/icons/fr.png'
		});
	}

	mdgClick = (e) => {
		this.setState({
			pays: 'MDG',
			icon: '/icons/mdg.png'
		});
	}

	swiClick = (e) => {
		this.setState({
			pays: 'SWI',
			icon: '/icons/swi.png'
		});
	}

	render(){

		return(
			<div>
				<nav className="navbar navbar-expand-sm fixed-top p-0 justify-content-between d-flex flex-column top-bar" style={{color:'#fff', backgroundColor:'#61CCF8'}}>
				  	<div className="d-flex justify-content-between align-items-center w-100">
					  	<ul className="navbar-nav">
						  	<li className="nav-item d-flex p-1 align-items-center">
						   		<span className="navbar-brand text-light font-weight-bold" style={{marginLeft:'15px'}}><strong>Saifaire</strong></span>
						   		{/*<select name="Pays" className="pays form-control form-control-sm" id="idPays"  data-flag="true" style={{height:'20px'}}>
							    	<option value="">Pays</option>
								</select>*/}
								 <div className="dropdown">
	                                <button className="btn dropdown-toggle text-light" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
	                                    <img className="drapeau" src={this.state.icon} alt={this.state.iconAlt} style={{width:'25px'}}/>
	                                </button>
	                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
	                                    <button onClick={this.paysClick} className="dropdown-item"><img className="drapeau" src="/icons/def.png" alt="Belgique" style={{width:'20px', marginRight:'10px'}}/>Pays</button>
	                                    <button onClick={this.belClick} className="dropdown-item" ><img className="drapeau" src="/icons/blg.png" alt="Belgique" style={{width:'20px', marginRight:'10px'}}/>Belgique</button>
	                                    <button onClick={this.cndClick} className="dropdown-item" ><img className="drapeau" src="/icons/cnd.png" alt="Canada" style={{width:'20px', marginRight:'10px'}}/>Canada</button>
	                                    <button onClick={this.frClick} className="dropdown-item" ><img className="drapeau" src="/icons/fr.png" alt="France" style={{width:'20px', marginRight:'10px'}}/>France</button>
	                                    <button onClick={this.mdgClick} className="dropdown-item" ><img className="drapeau" src="/icons/mdg.png" alt="madagascar" style={{width:'20px', marginRight:'10px'}}/>Madagascar</button>
	                                    <button onClick={this.swiClick} className="dropdown-item" ><img className="drapeau" src="/icons/swi.png" alt="Suisse" style={{width:'20px', marginRight:'10px'}}/>Suisse</button>
	                                </div>
	                            </div>
						   	</li>
						</ul>
						<ul className="nav navbar-nav d-flex flex-end justify-content-around w-50">
						    <li className="nav-item">
						    	<span className=""><u>Nous contacter:</u></span>
					    	</li>
					    	<li className="nav-item">
						    	<span><i className="bi bi-telephone m-2"></i><u>+261342365204</u></span>
					    	</li>
						    <li className="nav-item">
						      	<span><i className="bi bi-envelope m-2"></i><u><a href="mailto:guiduchoix2424@gmail.com" className="link-light">guiduchoix2424@gmail.com</a></u></span>
						    </li>
					 	</ul>
				 	</div>
				 	{/*<div className="d-flex justify-content-between align-items-center w-100 mt-0">
					 	<ul>
					 		<li className="nav-item d-flex justify-content-start">
							<select name="Pays" className="pays form-control form-control-sm" id="idPays">
							    <option value="">Pays</option>
							</select>
							<select name="etat" className="etat form-control form-control-sm" id="idEtat">
								<option value="">Region</option>
							</select>
							<select name="ville" className="ville form-control form-control-sm" id="idVille">
								<option value="">Ville</option>
							</select>
							</li>
					 	</ul>
				 	</div>*/}
				</nav>
				<SaifairePresentation/>
				<Services pays={this.state.pays}/>
				<Footer/>
			</div>
		)

	}

}

export default Saifaire;