import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import Routing from './Routing';

//Components
import Saifaire from './pages/Saifaire';
import SaifaireExpo from './pages/SaifaireExpo';

ReactDOM.render(
  <React.StrictMode>
      <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);