import React , {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link, NavLink} from 'react-router-dom';

class TopbarAprox extends Component {

	state = {
		pays : '',
		src : '',
		alt : ''
	}



	componentDidMount(props){
		const pays = this.props.pays;
		const bel ="bel";
		const cnd ="cnd";
		const fr ="fr";
		const mdg ="mdg";
		const swi ="swi";

		switch (pays){
			case bel:
				this.setState({
					pays : 'bel',
					src : '/icons/blg.png',
					alt : 'Belgique'
				});
				break;

			case cnd:
				this.setState({
					pays : 'cnd',
					src : '/icons/cnd.png',
					alt : 'Canada'
				});
				break;

			case fr:
				this.setState({
					pays : 'fr',
					src : '/icons/fr.png',
					alt : 'France'
				});
				break;

			case mdg:
				this.setState({
					pays : 'mdg',
					src : '/icons/mdg.png',
					alt : 'Madagascar'
				});
				break;

			case swi:
				this.setState({
					pays : 'swi',
					src : '/icons/swi.png',
					alt : 'Suisse'
				});
				break;

			default :
				this.setState({
					pays : 'pays',
					src : '/icons/def.png',
					alt : 'Pays'
				});
				break;

		}
	}

	paysClick = (e) => {
		this.setState({pays: ''});
		this.props.parentCallBack(this.state.pays);
		e.preventDefault();
	}

	mdgClick = (e) => {
		this.setState({pays: 'MDG'});
		this.props.parentCallBack(this.state.pays);
		e.preventDefault();
	}

	swiClick = (e) => {
		this.setState({pays: 'SWI'});
		this.props.parentCallBack(this.state.pays);
		e.preventDefault();
	}

	altSwitch = (props) => {

		console.log(props);

	}

	render(){
		return(
			<nav className="navbar navbar-expand-md fixed-top position-fixed p-2 pb-3 d-flex justify-content-between flex-column top-bar" style={{color:'#fff', backgroundColor:'#61CCF8'}}>
				<div className="d-md-flex justify-content-between w-100">
					<div className="d-flex align-items-center position-absolute">
						<strong><Link to="/saifaireexpo" className="navbar-brand text-white">SaifairExpo</Link></strong>
						<div className="dropdown">
                            <button className="btn text-light" type="button" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false">
                                <img className="drapeau" src="https://suppexpo.guiduchoix24.com/assets/images/mg(20).png" alt="madagascar"/>
                            </button>
                        </div>
                    </div>

					<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" style={{marginLeft:'90%', width:'40px' ,height:'30px', padding:'0px'}}>
						<span class="navbar-toggler-icon" style={{width:'30px'}}></span>
					</button>

				  	<div className="w-100">
					  	<div className="collapse navbar-collapse w-100 flex-column mr-0" id="collapsibleNavbar">
							<ul className="nav navbar-nav d-flex w-100 justify-content-end">
								<div className="d-sm-flex justify-content-around mt-4 mt-md-1">
									<li className="nav-item d-none d-md-block">
										<span className="" style={{marginLeft:'20px'}}><u>Nous contacter:</u></span>
									</li>
									<li className="nav-item">
										<span style={{marginLeft:'20px'}}><i className="bi bi-telephone m-2"></i><u>+261342365204</u></span>
									</li>
									<li className="nav-item">
									  	<span style={{marginLeft:'20px', marginRight:'20px'}}><i className="bi bi-envelope m-2"></i><u><a href="mailto:guiduchoix2424@gmail.com" className="link-light">guiduchoix2424@gmail.com</a></u></span>
									</li>
								</div>
							</ul>
						</div>
					</div>
				</div>
			</nav>
		)
	}
}
export default TopbarAprox;
