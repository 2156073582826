import React , {Component} from 'react';
import ReactDOM from 'react-dom';

class Footer extends Component {
	render(){
		return(
			<footer className="bg-light text-center text-white mt-5">
				{/* Grid container */}
				<div className="container p-4 pb-0">
					{/* Section: Social media */}
					<section className="mb-4">
						{/* Facebook */}
						<a className="btn btn-primary btn-floating m-1 border-0 rounded-circle" style={{backgroundColor:'#3b5998',}} href="#!" role="button">
							<i className="bi bi-facebook"></i>
						</a>

						{/* Twitter */}
						<a className="btn btn-primary btn-floating m-1 border-0 rounded-circle" style={{backgroundColor:'#55acee'}} href="#!" role="button">
							<i className="bi bi-twitter"></i>
						</a>

						{/* Google */}
						<a className="btn btn-primary btn-floating m-1 border-0 rounded-circle" style={{backgroundColor: '#dd4b39'}} href="#!" role="button">
							<i className="bi bi-google"></i>
						</a>

						{/* Instagram */}
						<a className="btn btn-primary btn-floating m-1 border-0 rounded-circle" style={{backgroundColor: '#ac2bac'}} href="#!" role="button">
							<i className="bi bi-instagram"></i>
						</a>

						{/* Linkedin */}
						<a className="btn btn-primary btn-floating m-1 border-0 rounded-circle" style={{backgroundColor: '#0082ca'}} href="#!" role="button">
							<i className="bi bi-linkedin"></i>
						</a>
						{/* Github */}
						<a className="btn btn-primary btn-floating m-1 border-0 rounded-circle" style={{backgroundColor: '#333333'}} href="#!" role="button">
							<i className="bi bi-github"></i>
						</a>
					</section>
					{/* Section: Social media */}
				</div>
				{/* Grid container */}

				{/* Copyright */}
				<div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
					© 2022 Copyright:
					<a className="text-white" href="#">saifaire.com</a>
				</div>
				{/* Copyright */}
			</footer>
		)
	}
}
export default Footer;