import React , {Component} from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, BrowserHistory, IndexRoute} from 'react-router';
import { BrowserRouter, Routes } from "react-router-dom";

import TopbarSfExpo from './../components/TopbarSfExpo';
import SaifaireExpoPresentation from './../components/SaifaireExpoPresentation';
import ProximiteSfExpo from './../components/ProximiteSfExpo';
import AutresExpo from './../components/AutresExpo';
import MaSfExpo from './../components/MaSfExpo';
import Footer from './../components/Footer';

import withRouter from './../components/withRouter';


class SaifaireExpo extends Component {

	state = {
		pays : '',
	}

	componentDidMount(){
		this.setState({
			pays : this.props.params.pays,
		})
		window.scrollTo(0, 0);
	}

	render(){
		return(
			<div>
				<TopbarSfExpo pays={this.state.pays}/>
				<SaifaireExpoPresentation/>
				<ProximiteSfExpo pays={this.state.pays}/>
				<AutresExpo pays={this.state.pays}/>
				<Footer/>
			</div>
		)

	}

}

export default withRouter(SaifaireExpo);
