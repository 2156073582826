import React, {Component, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Link, useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import AOS from "aos";
import withReactContent from 'sweetalert2-react-content';

import "../../../node_modules/aos/dist/aos.css";
import expo from './../../assets/logos/saifairexpo.png';
import academy from './../../assets/logos/sfAcademy.png';
import event from './../../assets/logos/sfEvents.png';
import autres from './../../assets/logos/sfAutres.png';
import expoIll from './../../assets/images/expo.jpg';
import academyIll from './../../assets/images/academy.jpg';
import eventIll from './../../assets/images/event.jpg';
import autresIll from './../../assets/images/autres.jpg';


var Presentation = (props) => {

	useEffect(() => {
	    AOS.init();
	    AOS.refresh();
	}, []);

	const navigate = useNavigate();

	async function checkPays(props){
		console.log('le props :'+props);
		if(props !== null){
			navigate('/commerces');
		}else{
			const { value: fruit } = await Swal.fire({
			  title: 'Veuillez choisir votre Pays',
			})
            return false;
		}
	}

	return(
		<div className="container-fluid pb-0" style={{width:'100%'}}>
			<h1 className="text-center p-0 text-secondary"><strong>NOS SERVICES</strong></h1>
			<p className="text-center pt-3">Saifaire vous offre les meilleurs services pour developper votre entreprise en exposant differents dommaines<br/>
			à savoir, le commerce, les évènements, les formations, et bien d'autres encore!</p>
			<div className="card-deck d-flex flex-column p-0 pt-0 pb-0 m-0">

				<div className='d-sm-flex-column align-items-center d-md-flex justify-content-center w-100 mb-0 p-2 p-md-5 pt-5 divtoHover sfExpo' data-aos="fade-up" data-aos-delay="600" data-aos-duration="800">
					<div className="rounded-0 border-0 p-0 w-100 w-lg-50">
						<img className="d-none d-lg-block" src={expoIll} style={{height:"90%", width:'90%', borderRadius:'87% 13% 34% 66% / 34% 28% 72% 66% '}} />
					</div>
					<div className="rounded-0 border-0 bg-none w-100 w-lg-50">
						<div className="card-body border-0 flex-column justify-content-around p-0">
							<h1 className="presTitle text-secondary align-items-center p-0 w-100">Petits et grands commerces, <br/>Produits et services, <br/>  <span className="presTitle">aProximite</span> vous offre tout  <br/> au meme endroit.</h1>
							<button onClick={() => checkPays(props.pays)} className="btn btn-lg d-flex" style={{textDecoration:'none', width:'150px'}}><span className="hover-underline"><strong>VISITER</strong><i className="bi bi-arrow-right"></i></span></button>
						</div>
					</div>
				</div>

				<div className='d-sm-flex-column align-items-center d-md-flex justify-content-center w-100 mb-0 p-2 p-md-5 pt-5 divtoHover sfExpo' data-aos="fade-up" data-aos-delay="600" data-aos-duration="800">
					<div className="rounded-0 border-0 bg-none w-100 w-lg-50">
						<div className="card-body border-0 flex-column justify-content-around">
							<h1 className="presTitle text-secondary align-items-center p-0 w-100">Spectacles et cabarets, <br/>Seminaires et conferences, <br/>  <span className="presTitle">SaifaireEvent</span> vous propose le meilleur salon d'evenement.</h1>
							<button onClick={() => checkPays(props.pays)} className="btn btn-lg d-flex" style={{textDecoration:'none', width:'150px'}}><span className="hover-underline"><strong>VISITER</strong><i className="bi bi-arrow-right"></i></span></button>
						</div>
					</div>
					<div className="rounded-0 border-0 p-0 w-100 w-lg-50">
						<img className="d-none d-lg-block" src={eventIll} style={{height:"90%", width:'90%', borderRadius:'87% 13% 34% 66% / 34% 28% 72% 66% '}} />
					</div>
				</div>

				<div className='d-sm-flex-column align-items-center d-md-flex justify-content-center w-100 mt-0 p-2 p-md-5 pt-5 divtoHover sfExpo' data-aos="fade-up" data-aos-delay="600" data-aos-duration="800">
					<div className="rounded-0 border-0 p-0 w-100 w-lg-50">
						<img className="d-none d-lg-block" src={autresIll} style={{height:"90%", width:'90%', borderRadius:'87% 13% 34% 66% / 34% 28% 72% 66% '}} />
					</div>
					<div className="rounded-0 border-0 bg-none w-100 w-lg-50">
						<div className="card-body border-0 flex-column justify-content-around">
							<h1 className="presTitle text-secondary align-items-center p-0 w-100">Recherche d'emplois, <br/>Guides professionnels, <br/>  <span className="presTitle">talentUP</span> vous offre le meilleur orientation professionnel.</h1>
							<button onClick={() => checkPays(props.pays)} className="btn btn-lg d-flex" style={{textDecoration:'none', width:'150px'}}><span className="hover-underline"><strong>VISITER</strong><i className="bi bi-arrow-right"></i></span></button>
						</div>
					</div>
				</div>

				{/*<div className='w-100 mb-5 mt-5 row divtoHover' style={{}}>
					<div className="card rounded-0 border-0 col-6">
						<div className="card-body">
							<img src={autres} className="card-title"/>
							<p className="card-text text-center mt-3">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.
							</p>
						</div>
					</div>
					<div className="card rounded-0 border-0 col-4 p-0">
						<img src={autresIll} style={{height:"280px"}} />
					</div>
					<div className="card rounded-0 border-0 col-2 visiter">
						<span className="m-auto hover-underline" onClick={this.checkPays}><strong>VISITER</strong><i className="bi bi-arrow-right"></i></span>
					</div>
				</div>*/}

			</div>
		</div>
	);
}

export default Presentation;