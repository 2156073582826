import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import expo from './../assets/logos/sfExpoLG.png';

class SaifaireExpoPresentation extends Component{
	render(){
		return(
			<div className="container-fluid pb-5 pt-5 presentation sfExpo">
				<div className="w-100 mt-1 p-5 pt-0 d-flex align-items-center justify-content-around row">
					<div className="col-md-4">
						 <img src={expo} style={{width:'100%', height:'100%'}}/>
					</div>
					<div className="col-md-7">
						<p className="text-justify" style={{fontSize:'15px'}}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
							ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in 
							voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat 
							non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Duis aute irure dolor in reprehenderit in 
							voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat 
							non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</p>
					</div>
				</div>
				<div className='w-75 border-dark rounded bg-secondary d-flex justify-content-center m-auto mt-3 animation3D' style={{height:'400px'}}>
					<a className="mt-auto m-4 position-relative text-decoration-none text-light">Visiter rapidement</a>
				</div>
			</div>
		)
	}
}

export default SaifaireExpoPresentation;