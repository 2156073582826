import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import ChoixRegion from './ChoixRegion';
import Commercant from './Commercant';
import { Link, NavLink, useNavigate} from 'react-router-dom';

class ProximiteSfExpo extends Component{

	state = {
		pays : this.props.pays,
	}

	componentDidMount(){
		const commerces = "commerces/"+this.props.pays;
		const services = "venteservices/"+this.props.pays;
		const grandesespaces = "grandesespaces/"+this.props.pays;
		const autres = "autres/"+this.props.pays;

		console.log(commerces);
	}


	render(){
		return(
			<div className="container-fluid justify-content-center pt-5 services">
				<div className="d-flex flex-column justify-content-center mt-5 pt-5">
					<h2 className="text-center mt-0 p-0 pt-0 text-secondary"><strong>A PROXIMITE</strong></h2>
					<div className="container w-75">
						<p className="text-justify pt-4 text-center">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
							ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
							voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
							non proident, sunt in culpa qui offrira leurs services.
						</p>
					</div>
				</div>
				<div>
					<hr className="hr-text mt-5 pt-5" style={{color:'#8ea4f8'}}/>
					<Link to="/commerces" style={{textDecoration:'none', color:'#8ea4f8', textAlign:'center'}}><h2><strong>COMMERCES</strong></h2></Link>
					<div className="container w-75">
						<p className="text-justify pt-4 text-center">
							Vous trouverez dans cette séction les petits et grands commerçants<br/>
							près de chez vous comme les boulangeries, supermarchés, centres commerciaux, places de marchés,...etc 
						</p>
					</div>
					<div className="d-flex justify-content-around row">
						<Commercant categorie="proximite" start="3" limit="3" images="/images/1.jpg" lien="/commerces"/>
					</div>
					<div className="container d-flex justify-content-center">
						<Link to="/commerces"><h5 className="mt-3 mb-3 ml-auto position-relative btn text-white" style={{backgroundColor:'#80aaf9'}}>Voir plus<i className="bi bi-arrow-right"></i></h5></Link>
					</div>
				</div>


				<div>
					<hr className="hr-text mt-5 pt-5" style={{color:'#8ea4f8'}}/>
					<Link to="/venteservices" style={{textDecoration:'none', color:'#8ea4f8', textAlign:'center'}}><h2><strong>SERVICES</strong></h2></Link>
					<div className="container w-75">
						<p className="text-justify pt-4 text-center">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
							ut labore et dolore magna aliqua.
						</p>
					</div>
					<div className="d-flex justify-content-around row">
						<Commercant categorie="proximite" start="3" limit="3" images="/images/6.jpg" lien="/standdefaut"/>
					</div>
					<div className="container d-flex justify-content-center">
						<Link to="/venteservices"><h5 className="mt-3 mb-3 ml-auto position-relative btn text-white" style={{backgroundColor:'#80aaf9'}}>Voir plus<i className="bi bi-arrow-right"></i></h5></Link>
					</div>
					<ChoixRegion id="services" lien='/venteservices'/>
				</div>


				<div>
					<hr className="hr-text mt-5 pt-5" style={{color:'#8ea4f8'}}/>
					<Link to="/grandesespaces" style={{textDecoration:'none', color:'#8ea4f8', textAlign:'center'}}><h2><strong>GRANDES ESPACES</strong></h2></Link>
					<div className="container w-75">
						<p className="text-justify pt-4 text-center">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
							ut labore et dolore magna aliqua.
						</p>
					</div>
					<div className="d-flex justify-content-around row">
						<Commercant categorie="proximite" start="3" limit="3" images="/images/5.jpg" lien="/standsupermarche"/>
					</div>
					<div className="container d-flex justify-content-center">
						<Link to="/grandesespaces"><h5 className="mt-3 mb-3 ml-auto position-relative btn text-white" style={{backgroundColor:'#80aaf9'}}>Voir plus<i className="bi bi-arrow-right"></i></h5></Link>
					</div>
					<ChoixRegion id="grandsE" lien='/grandesespaces'/>
				</div>

			</div>
		)
	}
}

export default ProximiteSfExpo;
