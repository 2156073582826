import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Commercant from './Commercant';
import {Link, useNavigate} from 'react-router-dom';

class ProximiteSfExpo extends Component{
	render(){
		return(
			<div className="container-fluid justify-content-center mt-5">
				<div className="d-flex flex-column justify-content-center">
					<hr className="hr-text" data-content="Ma SaifareExpo" />
					<div className="container w-75">
						<p className="text-justify p-4">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
							ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in 
							voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat 
							non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</p>
					</div>
				</div>
			</div>
		)
	}
}

export default ProximiteSfExpo;