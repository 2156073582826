import React, {Component} from 'react';
import Background from './../assets/images/wave.svg';
import {Link, useNavigate} from 'react-router-dom';

class Commercant extends Component {

	
	state = {
		items: [],
		isLoaded: false,
		pays : this.props.pays,
		categorie: this.props.categorie,
		start: this.props.start,
		limit: this.props.start,
		lien: this.props.lien,
		images: this.props.images,
		api: this.props.api,
		desc: this.props.desc
	};


	componentDidMount(){
		var limit = 0;
		if(this.props.start == 3){
			limit = 3;
		}else{
			limit = Math.floor(Math.random() * 10) + 5;
		}
		var request = '//saifairexpo-backend.saifairacademy.net/api/'+this.props.api+'';
		console.log(this.props.lien);
			fetch(request)
				.then(res => res.json())
				.then(json => {
					this.setState({
						isLoaded: true,
						items:json,
					})
				});

	}

	componentDidUpdate(){
		const nextState = this.props.start;
		console.log(nextState+" et "+this.state.start);
		if (nextState !== this.state.start){
			this.setState({
				items: [],
				isLoaded: false,
				pays : this.props.pays,
				categorie: this.props.categorie,
				start: nextState,
				limit: nextState,
				lien: this.props.lien,
				images: this.props.images,
				api: this.props.api,
				desc: this.props.desc
			});
			var request = '//saifairexpo-backend.saifairacademy.net/api/'+this.props.api+'';
			console.log(request);
			fetch(request)
				.then(res => res.json())
				.then(json => {
					this.setState({
						isLoaded: true,
						items: json,
					})
				});
		}
	}

	render(){
	    var { items, isLoaded } = this.state;
	    if(this.props.api != 'getStand1'){
	    	console.log(this.props.api);
		    return(
		    	items.map((item , key)=> (

			    	<div className="card m-5 mb-3 p-0 cartes" style={{width:"380px", borderRadius:"15px"}} key={key}>
						<img className="card-img-top" src={'/images/'+item.couverture+''} alt="Card image" style={{height:"230px", maxWidth:"380px", borderRadius:"15px", position:'bottom'}}/>
						<Link to={'/'+item.lien+''}>
						<div className="card-img-overlay p-3 d-flex flex-column justify-content-between" style={{minHeight:'40%',borderRadius:"0px 0px 20px 20px", borderTopRightRadius:'300px 15px', borderTopLeftRadius:'300px 15px',height:"70px",marginTop:"auto", backgroundColor:"#4cdbf6"}}>
							<div>
								<h4 className="card-title text-white" style={{fontSize:"15px",}}><strong>{item.nom_stand}</strong></h4>
							</div>
							<div className="d-flex align-self-end">
								<p className="card-text text-white m-0 p-0 pb-3" style={{fontSize:"12px"}}><strong>{item.description}</strong></p>
								<button className="btn text-white mt-2 decouvrir" style={{height:'30px', paddingTop:'0px', paddingBottom:'0px',fontSize:"14px"}}>Découvrir</button>
							</div>
						</div>
						</Link>
					</div>

				))
		    )
		}else{
			console.log('getstand');
			return(
				items.map((item , key)=> (

			    	<div className="card m-5 mb-3 p-0 cartes" style={{width:"380px", borderRadius:"15px"}} key={key}>
						<img className="card-img-top" src={this.props.images} alt="Card image" style={{height:"230px", maxWidth:'380px', borderRadius:"15px", position:'bottom'}}/>
						<Link to={this.state.lien}>
						<div className="card-img-overlay p-3 d-flex flex-column justify-content-between" style={{minHeight:'40%',borderRadius:"0px 0px 20px 20px", borderTopRightRadius:'300px 15px', borderTopLeftRadius:'300px 15px',height:"70px",marginTop:"auto", backgroundColor:"#4cdbf6"}}>
							<div>
								<h4 className="card-title text-white" style={{fontSize:"15px",}}><strong>{this.props.nom}</strong></h4>
							</div>
							<div className="d-flex align-self-end">
								<p className="card-text text-white m-0 p-0" style={{fontSize:"12px"}}><strong>{this.props.desc}</strong></p>
								<button className="btn text-white mt-2 decouvrir" style={{height:'30px', paddingTop:'0px', paddingBottom:'0px',fontSize:"14px"}}>Découvrir</button>
							</div>
						</div>
						</Link>
					</div>

				))
			)
		}
	}
}

export default Commercant;
