import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Commercant from './Commercant';
import {Link, useNavigate} from 'react-router-dom';

class ProximiteSfExpo extends Component{
	render(){
		return(
			<div className="container-fluid justify-content-center mt-0 pt-0">
				<div className="d-flex flex-column justify-content-center">
					<hr className="hr-text mt-3 pt-3" style={{color:'#8ea4f8'}}/>
					<Link to="/autres" style={{textDecoration:'none', color:'#8ea4f8', textAlign:'center'}}><h2><strong>AUTRES FOURNISSEURS</strong></h2></Link>
					<div className="container w-75">
						<p className="text-justify pt-4">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
							ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in 
							voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat 
							non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</p>
					</div>
					<div className="d-flex justify-content-around row">
						<Commercant categorie="autres" start="3" limit="3" images="/images/2.jpg" lien="#"/>
					</div>
					<div className="container d-flex justify-content-center">
						<Link to="/autres"><h5 className="mt-3 mb-3 ml-auto position-relative btn text-white" style={{backgroundColor:'#80aaf9'}}>Voir plus<i className="bi bi-arrow-right"></i></h5></Link>
					</div>
				</div>
			</div>
		)
	}
}

export default ProximiteSfExpo;