import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link, useNavigate} from 'react-router-dom';
import $, {toogle} from 'jquery';

import Commercant from './../../../components/Commercant';


class AproxCommerces extends Component{

	state = {
		pays: 'mdg',
		region: 'Toutes',
		categorie : 'Les plus populaires',
		start: '7',
		lienStand: 'standdefaut',
		image: '/images/1.jpg',
		api:'getStand',
		nom: 'Exposant',
		desc: ''
	}

	showCat = () => {
		alert('kez');
		$('#categories').fadeToogle("slow");
	}

	categories = (start, categories, lien="standdefaut", image="/images/1.jpg", api="getStand", nom="Exposant", desc="Le meilleur endroit pour tout vos achats") =>{
		console.log(lien);
		$('#categories').Hide();
		this.setState({
			categorie: categories,
			start: start,
			lienStand: lien,
			image: image,
			api: api,
			nom: nom,
			desc: desc
		});
	}

	componentDidMount(){
		window.scrollTo(0,0);
		$("#showCat").click(function(){
			$("#categories").fadeToggle("slow");
		});
	}

	regions = (start, region) =>{
		this.setState({
			region: region,
      start: start,
		});
	}

	componentDidUpdate(){
	}

	render(){
		return(
			<div className="container-fluid justify-content-around mt-md-4 mt-lg-3 pt-md-4 pt-lg-3" style={{minHeight:'100vh'}}>
				<div className="d-flex flex-column m-0 p-4 mt-md-5 mt-0 pb-0 navigation" style={{backgroundColor: "#f2f2f2"}}>

					<form className="form d-md-flex justify-content-between" >
						<div className="d-sm-flex align-items-center justify-content-center">
							<div className="dropdown mb-3 mb-sm-0" style={{marginRight:'30px', marginLeft:'10px'}}>
								<span type="button" className="text-secondary dropdown-toggle" style={{width:"200px",}} id="showCat">
                  					<strong>Categories</strong>
								</span>
							</div>

							<div className="dropdown" style={{marginRight:'30px', marginLeft:'10px'}}>
								<span type="button" className="text-secondary dropdown-toggle dropdown2-toggle"  data-toggle="dropdown" style={{width:"200px",}}>
								<strong>Regions</strong>
								</span>

								<div class="dropdown-menu" id="dropdown2">
									<div className="d-flex justify-content-around">
										<div className="m-2 mt-2 mb-3">
											<p className=" mt-3 m-3"><u>Madagascar</u></p>
											{/*<Link to="#" className="dropdown-item mt-2" onClick={ () => this.regions('Toutes') } style={{ marginRight: '5px'}}><span>Toutes</span></Link>*/}
											<Link to="#" className="dropdown-item mt-2" onClick={ () => this.regions(9,'Toutes') } style={{ marginRight: '5px'}}><span>Toutes</span></Link>
											<Link to="#" className="dropdown-item mt-2" onClick={ () => this.regions(7,'Antananarivo') } style={{ marginRight: '5px'}}><span>Antananarivo</span></Link>
											<Link to="#" className="dropdown-item mt-2" onClick={ () => this.regions(3,'Diego Suarez') } style={{ marginRight: '5px'}}><span>Diego Suarez</span></Link>
											<Link to="#" className="dropdown-item mt-2" onClick={ () => this.regions(2,'Majunga') } style={{ marginRight: '5px'}}><span>Majunga</span></Link>
											<Link to="#" className="dropdown-item mt-2" onClick={ () => this.regions(6,'Tamatave') } style={{ marginRight: '5px'}}><span>Tamatave</span></Link>
											<Link to="#" className="dropdown-item mt-2" onClick={ () => this.regions(4,'Fianarantsoa') } style={{ marginRight: '5px'}}><span>Fianarantsoa</span></Link>
											<Link to="#" className="dropdown-item mt-2" onClick={ () => this.regions(2,'Tulear')} style={{ marginRight: '5px'}}><span>Tulear</span></Link>
										</div>
									</div>
							  	</div>
							</div>
						</div>

						<div className="w-100 d-sm-flex p-2 justify-content-center mt-2 mt-md-0">
							<div className="input-group align-items-center justify-content-end" style={{marginRight:'40px'}}>
								<input type="text" className="form-control" placeholder="Rechercher un exposant" id="search" name="search" style={{maxHeight:'40px', maxWidth:'450px'}}/>
								<div className="input-group-append">
									<button  className="input-group-text align-items-center"><i className="bi bi-search"></i></button>
								</div>
							</div>
							<button className="btn text-light mt-3 mt-sm-0" style={{backgroundColor:"#ff6cb5", borderRadius:"50px 50px 50px 50px", minWidth:'200px'}}><strong><a href="http://localhost:3001/creerstand" className="text-white text-decoration-none">Espace exposant</a></strong></button>
						</div>
					</form>
					<hr className="" />
				</div>

				<div id="categories" style={{position:'absolute', zIndex:'1', width:'98vw', backgroundColor:'#fff', display:'none'}}>
					<div className="d-md-flex justify-content-around">
						<div className="m-3 mb-3">
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('3', 'les_plus_populaires') } style={{ marginRight: '5px'}}><span>Les plus populaires</span></Link>
							<p className="m-4"><u>Alimentaires</u></p>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('3',  'boucheries') } style={{ marginRight: '5px'}}><span>Boucheries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('9',  'boulangeries') } style={{ marginRight: '5px'}}><span>Boulangeries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('5',  'charcuteries') } style={{ marginRight: '5px'}}><span>Charcuteries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('5',  'Chocolateries') } style={{ marginRight: '5px'}}><span>Chocolateries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('5',  'Confiseries') } style={{ marginRight: '5px'}}><span>Confiseries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('5',  'Cremeries') } style={{ marginRight: '5px'}}><span>Cremeries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('12', 'debits_de_boissons') } style={{ marginRight: '5px'}}><span>Debits de boissons</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('19', 'epiceries') } style={{ marginRight: '5px'}}><span>Epiceries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('19', 'Fast-foods') } style={{ marginRight: '5px'}}><span>Fast-foods</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('19', 'Glacier') } style={{ marginRight: '5px'}}><span>Glacier</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('19', 'Grossistes Alimentaires') } style={{ marginRight: '5px'}}><span>Grossistes Alimentaires</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('15', 'patisseries') } style={{ marginRight: '5px'}}><span>Patisseries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('15', 'Pizzerias') } style={{ marginRight: '5px'}}><span>Pizzerias</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('23', 'plats_a_emporter')} style={{ marginRight: '5px'}}><span>Plats a emporter</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('29', 'Poissonneries') } style={{ marginRight: '5px'}}><span>Poissonneries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('25', 'Restaurants') } style={{ marginRight: '5px'}}><span>Restaurants</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('25', 'Traiteurs') } style={{ marginRight: '5px'}}><span>Traiteurs</span></Link>
						</div>
						<div className="m-3 mt-5 mt-md-3 mb-3">
							<p className=" mt-4 text-secondary"><u>Automobiles</u></p>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('33', 'Pieces detaches') } style={{ marginRight: '5px'}}><span>Pieces detaches</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Velos et motos') } style={{ marginRight: '5px'}}><span>Velos et motos</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Voitures') } style={{ marginRight: '5px'}}><span>Voitures</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Stations d\'essences') } style={{ marginRight: '5px'}}><span>Stations d'essences</span></Link>
						
							<p className=" mt-4 text-secondary"><u>Electromenager</u></p>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('33', 'Ameublements') } style={{ marginRight: '5px'}}><span>Ameublements</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Appareils electromenagers') } style={{ marginRight: '5px'}}><span>Appareils electromenagers</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Articles menagers') } style={{ marginRight: '5px'}}><span>Articles menagers</span></Link>

							<p className=" mt-4 text-secondary"><u>Electroniques / Informatiques</u></p>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('33', 'Appareils elctroniques') } style={{ marginRight: '5px'}}><span>Appareils elctroniques</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Cartes de recharge') } style={{ marginRight: '5px'}}><span>Cartes de recharge</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Articles menagers') } style={{ marginRight: '5px'}}><span>Consomables informatique</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Materiels informatiques') } style={{ marginRight: '5px'}}><span>Materiels informatiques</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Telephonie mobile') } style={{ marginRight: '5px'}}><span>Telephonie mobile</span></Link>
						</div>
						<div className="m-3 mt-5 mt-md-3 mb-3">
							<p className=" mt-4 text-secondary"><u>Fournitures</u></p>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('33', 'Bricolages') } style={{ marginRight: '5px'}}><span>Bricolages</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Fournitures de bureaux') } style={{ marginRight: '5px'}}><span>Fournitures de bureaux</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Fournitures scolaires') } style={{ marginRight: '5px'}}><span>Fournitures scolaires</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Librairies') } style={{ marginRight: '5px'}}><span>Librairies</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Papeteries') } style={{ marginRight: '5px'}}><span>Papeteries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Quicailleries') } style={{ marginRight: '5px'}}><span>Quicailleries</span></Link>
							
							<p className=" mt-4 text-secondary"><u>Grandes espaces</u></p>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('1', 'Centres comerciaux', 'standcentrecom', '/images/mall.jpg', 'getStand1', 'Wallmart', 'Le meilleur endroit pour tout vos achats') } style={{ marginRight: '5px'}}><span>Centres commerciaux</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('1', 'Marches de proximite', 'standmarche', '/images/market.jpg', 'getStand1', 'La grande place de St.Charles', 'Un grand marché avec divers stands et produits') } style={{ marginRight: '5px'}}><span>Marches de proximite</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('1', 'Supermarches', 'standsupermarche', '/images/3.jpg', 'getStand1', 'The big Shop', 'Les meilleurs produits pres de chez vous') } style={{ marginRight: '5px'}}><span>Supermarches</span></Link>

						</div>
						<div className="m-3 mt-5 mt-md-3">
							<p className=" mt-4 text-secondary"><u>Habillements</u></p>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('33', 'Accessoires de mode') } style={{ marginRight: '5px'}}><span>Accessoires de mode</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Bijouteries') } style={{ marginRight: '5px'}}><span>Bijouteries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Cosmetiques') } style={{ marginRight: '5px'}}><span>Cosmetiques</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Friperies') } style={{ marginRight: '5px'}}><span>Friperies</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Lingeries') } style={{ marginRight: '5px'}}><span>Lingeries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Opticiens') } style={{ marginRight: '5px'}}><span>Opticiens</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Parfumeries') } style={{ marginRight: '5px'}}><span>Parfumeries</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Produits de mode') } style={{ marginRight: '5px'}}><span>Produits de mode</span></Link>
							
							<p className=" mt-4 text-secondary"><u>Sante</u></p>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('33', 'Pharmacies') } style={{ marginRight: '5px'}}><span>Bricolages</span></Link>
							<Link to="#" className="dropdown-item mt-2 di1" onClick={ () => this.categories('35', 'Produits dietetiques') } style={{ marginRight: '5px'}}><span>Produits dietetiques</span></Link>
						</div>
					</div>
					<hr/>
				</div>
				<div className="d-flex flex-column justify-content-center pt-5" style={{zIndex:'0'}}>
					<div className="mt-0 mt-md-0 pt-sm-0">
						<p className="mt-5 pt-4 text-secondary"><Link to="/commerces/categories">Saifaire > </Link> <Link to={'/SaifaireExpo/'+this.props.pays}>Saifairexpo ></Link></p>
						<h6 className="text-secondary mt-5 mt-sm-0"><strong>Categorie : <u>{this.state.categorie}</u> || Region : <u>{this.state.region}</u></strong></h6>
						<div className="d-flex row justify-content-around" style={{paddingLeft:"30px", paddingRight:"30px"}}>
							<Commercant pays={this.state.pays} region={this.state.region} categorie="autres" start={this.state.start} limit="1" images={this.state.image} api={this.state.api} nom={this.state.nom} lien={`/${this.state.lienStand}`} desc={this.state.desc}/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default AproxCommerces;
