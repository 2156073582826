import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';

import expo from './../../assets/logos/sfExpoLG.png';


export default function SaifaireExpoPresentation(){
	return(
		<div className="container-fluid flex-column align-items-center pb-md-5 pt-5 presGleGroup">
			<div className="w-100 mt-1 p-5 pt-0 d-flex align-items-center justify-content-around">
				<div className="col-md-4 mt-5 d-none d-md-block">
					 <img src={expo} style={{width:'100%', height:'100%'}}/>
				</div>
				<div className="col-md-7 mt-5">
					<h1 className="presTitle text-secondary">Bienvenue <br/><span>  sur <span className="presTitle">SaifaireExpo</span> </span> <br/> <span>Votre partenaire en promotion.</span></h1>
					<button className="btn lpButton mt-5" style={{fontWeight:'bold'}}>Commencer la visite</button>
				</div>
			</div>
			{/*<div className='d-none d-md-block w-75 border-dark rounded bg-secondary d-flex justify-content-center m-auto mt-3 animation3D' style={{height:'400px'}}>
				<a className="mt-auto m-4 position-relative text-decoration-none text-light">Visiter rapidement</a>
			</div>*/}
		</div>
	)
}
