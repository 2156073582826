import React, {Component, useState} from 'react';
import ReactDOM from 'react-dom';
import {Link, useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import expo from './../assets/logos/saifairexpo.png';
import academy from './../assets/logos/sfAcademy.png';
import event from './../assets/logos/sfEvents.png';
import autres from './../assets/logos/sfAutres.png';
import expoIll from './../assets/images/expo.jpg';
import academyIll from './../assets/images/academy.jpg';
import eventIll from './../assets/images/event.jpg';
import autresIll from './../assets/images/autres.jpg';

var Presentation = (props) => {

	const navigate = useNavigate();

	async function checkPays(props){
		console.log('le props :'+props);
		if(props !== null){
			navigate('/saifaireexpo/'+props.toLowerCase());
		}else{
			const { value: fruit } = await Swal.fire({
			  title: 'Veuillez choisir votre Pays',
			})
            return false;
		}
	}

	return(
		<div className="container-fluid pt-5 pb-0 services">
			<h1 className="text-center mt-5 p-0 pt-5 text-secondary"><strong>NOS SERVICES</strong></h1>
			<p className="text-center pt-5">Saifaire vous offre les meilleurs services pour developper votre entreprise en exposant differents dommaines<br/>
			à savoir, le commerce, les évènements, les formations, et bien d'autres encore!</p>
			<div className="card-deck d-flex flex-column p-5 pb-0 m-0">

				<div className='w-100 mb-5 row divtoHover' style={{}}>
					<div className="card rounded-0 border-0 col-6 sfExpo">
						<div className="card-body border-0 d-flex flex-column justify-content-between">
							<img src={expo} className="card-title text-center" style={{maxWidth:'230px'}}/>
							<p className="card-text mt-3">
								Découvrez SaifaireExpo! <br/>
								SaifaireExpo est salon d'exposition dédié à promotion et la mise en avant des petites et moyennes entreprises.
								C'est une plateforme conçue pour favoriser le developpement des PME et TPE dans les monde entier
							</p>
							<button onClick={() => checkPays(props.pays)} className="btn d-flex justify-content-end align-self-end" style={{textDecoration:'none'}}><span className="hover-underline"><strong>VISITER</strong><i className="bi bi-arrow-right"></i></span></button>
						</div>
					</div>
					<div className="card rounded-0 border-0 col-6 p-0">
						<img src={expoIll} style={{height:"400px"}} />
					</div>
				</div>

				<div className='w-100 mb-5 row divtoHover' style={{}}>
					<div className="card rounded-0 border-0 col-6 p-0">
						<img src={academyIll} style={{height:"400px"}} />
					</div>
					<div className="card rounded-0 border-0 col-6">
						<div className="card-body border-0 d-flex flex-column justify-content-between">
							<img src={academy} className="card-title text-center" style={{maxWidth:'230px'}}/>
							<p className="card-text mt-3">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.
							</p>
							<Link to="#" className="d-flex justify-content-end align-self-end" style={{textDecoration:'none'}}><span className="hover-underline"><strong>VISITER</strong><i className="bi bi-arrow-right"></i></span></Link>
						</div>
					</div>
				</div>

				<div className='w-100 mb-5 row divtoHover' style={{}}>
					<div className="card rounded-0 border-0 col-6">
						<div className="card-body border-0 d-flex flex-column justify-content-between">
							<img src={event} className="card-title text-center" style={{maxWidth:'230px'}}/>
							<p className="card-text mt-3">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.
							</p>
							<Link to="#" className="d-flex justify-content-end align-self-end" style={{textDecoration:'none'}}><span className="hover-underline"><strong>VISITER</strong><i className="bi bi-arrow-right"></i></span></Link>
						</div>
					</div>
					<div className="card rounded-0 border-0 col-6 p-0">
						<img src={eventIll} style={{height:"400px"}} />
					</div>
				</div>

				<div className='w-100 mb-5 row divtoHover' style={{}}>
					<div className="card rounded-0 border-0 col-6 p-0">
						<img src={autresIll} style={{height:"400px"}} />
					</div>
					<div className="card rounded-0 border-0 col-6">
						<div className="card-body border-0 d-flex flex-column justify-content-between">
							<img src={autres} className="card-title text-center" style={{maxWidth:'230px'}}/>
							<p className="card-text mt-3">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.
							</p>
							<Link to="#" className="d-flex justify-content-end align-self-end" style={{textDecoration:'none'}}><span className="hover-underline"><strong>VISITER</strong><i className="bi bi-arrow-right"></i></span></Link>
						</div>
					</div>
				</div>

				<div className='w-100 mb-5 row divtoHover' style={{}}>
					<div className="card rounded-0 border-0 col-6">
						<div className="card-body border-0 d-flex flex-column justify-content-between">
							<img src={autres} className="card-title text-center" style={{maxWidth:'230px'}}/>
							<p className="card-text mt-3">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.
							</p>
							<Link to="#" className="d-flex justify-content-end align-self-end" style={{textDecoration:'none'}}><span className="hover-underline"><strong>VISITER</strong><i className="bi bi-arrow-right"></i></span></Link>
						</div>
					</div>
					<div className="card rounded-0 border-0 col-6 p-0">
						<img src={autresIll} style={{height:"400px"}} />
					</div>
				</div>

				{/*<div className='w-100 mb-5 mt-5 row divtoHover' style={{}}>
					<div className="card rounded-0 border-0 col-6">
						<div className="card-body">
							<img src={autres} className="card-title"/>
							<p className="card-text text-center mt-3">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
								ut labore et dolore magna aliqua.
							</p>
						</div>
					</div>
					<div className="card rounded-0 border-0 col-4 p-0">
						<img src={autresIll} style={{height:"280px"}} />
					</div>
					<div className="card rounded-0 border-0 col-2 visiter">
						<span className="m-auto hover-underline" onClick={this.checkPays}><strong>VISITER</strong><i className="bi bi-arrow-right"></i></span>
					</div>
				</div>*/}

			</div>
		</div>
	);
}

export default Presentation;